@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

@layer base {
    body {
        @apply font-[Lato];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover{
    background-image: linear-gradient(
        to right,
        rgba(84, 201, 103, 0.8),
        rgba(56, 101, 224, 0.8)
    )!important;
}